<!-- src/pages/Contact.svelte -->
<script>
    export let mainHeaderText, mainText; // Receive the prop from App.svelte
    $: mainHeaderText = "Contact";
    $: mainText = "Contact me to discuss your project, ask questions, or start a conversation. I'm here to help and provide information.";
  </script>
  

  
  <style>
   
  </style>
  <h2>page in works</h2>