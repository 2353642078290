<!-- src/Navbar.svelte -->
<script>
  import { Link } from "svelte-routing";
</script>

<nav>
  <ul>
    <li><Link to="/" class="nav-link">Home</Link></li>
    <li><Link to="/about" class="nav-link">About</Link></li>
    <li><Link to="/portfolio" class="nav-link">Portfolio</Link></li>
    <li><Link to="/contact" class="nav-link">Contact</Link></li>
  </ul>
</nav>

<style>
  nav {
    color: white;
    padding: 1em;
    font-weight: 300;
    letter-spacing: 2px;
    font-size: 1.1em;
  }

  ul {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: space-around;
  }

  li {
    margin: 0;
  }
  @media (max-width: 640px) {
    nav {
      font-size: 0.8em;
    }
  }
</style>
