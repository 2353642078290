<!-- src/pages/Home.svelte -->
<script>
  import { fade, slide } from "svelte/transition";
  export let mainHeaderText, mainText;

  $: mainHeaderText = "Dominik";
  $: mainText = "Always searching for new perspectives and stories in every frame.";
</script>

<div class="main-container">
  <section>
    <img class="mainPicture" src="/img/main/p1.jpg" alt="" />
    <section class="sectionAboutMe">
      <h2>About me</h2>
      <p>Photographer and designer with a passion for UI/UX, programming, and travel. Constantly evolving and embracing creativity.</p>
    </section>
  </section>

  <section>
    <section class="sectionAboutMe">
      <h2>text</h2>
      <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit.</p>
    </section>
    <div class="bgPicture">
      <img class="fgPicture" src="/img/main/p2fg.jpg" alt="" />
    </div>
  </section>
</div>

<style>
  .main-container {
    display: flex;
    width: 65%;
  }
  div > section:nth-child(1) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 50%;
  }
  div > section:nth-child(2) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 50%;
  }
  .mainPicture {
    width: 100%;
  }
  .sectionAboutMe {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding: 50px;
  }
  .sectionAboutMe h2 {
    margin: 0;
    color: var(--text3);
    font-size: 2em;
  }
  .sectionAboutMe p {
    font-size: 1.15em;
    color: var(--text3);
    letter-spacing: 0.2px;
  }
  .bgPicture {
    background-image: url("/img/main/p2bg.jpg");
    background-size: 100% 100%;
    width: 100%;
    height: 72%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .fgPicture {
    width: 100%;
    transform: scale(0.73);
    position: relative;
    box-shadow: #00000090 3px 3px 20px;
  }
  @media (max-width: 640px) {
    .main-container {
      width: 100%;
      flex-direction: column;
    }
    div > section:nth-child(1) {
      width: 100%;
    }
    div > section:nth-child(2) {
      width: 100%;
      text-align: right;
    }
  }
</style>
