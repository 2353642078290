<!-- src/pages/Portfolio.svelte -->
<script>
  import { slide } from "svelte/transition";
  import { onMount } from "svelte";
  import PhotoSwipeLightbox from "photoswipe/lightbox";
  import PhotoSwipe from "photoswipe";

  const jq = window.$;

  export let mainHeaderText, mainText; // Receive the prop from App.svelte

  // Change the text to "Portfolio" for this page
  $: mainHeaderText = "Portfolio";
  $: mainText = "A gallery of images that stir emotions and resonate deeply within me, each frame reflecting a personal journey and a unique perspective.";

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  // Import the image paths from the generated JSON file
  import imagePaths from "../../public/img/main/portfolio/list.json";

  // Prepend the directory path to each image
  let images = shuffleArray(imagePaths);

  onMount(() => {
    const lightbox = new PhotoSwipeLightbox({
      gallery: "#portfolio",
      children: "a",
      pswpModule: () => Promise.resolve(PhotoSwipe), // Static import
    });

    lightbox.init();
  });
</script>

<div class="main-container">
  <section>
    <div class="image-container" id="portfolio">
      {#each images as { src, payload }}
        <a
          target="_blank"
          href="/{src}"
          data-pswp-width={payload.width}
          data-pswp-height={payload.height}
          class={payload.orientation}
          rel="noreferrer"
          
        >
          <img class="" alt="Loading.." src="/{payload.thumb}" />
        </a>
      {/each}
    </div>
  </section>
</div>

<style>
  section {
    width: 100%;
  }
  .main-container {
    display: flex;
    width: 65%;
    overflow-y: auto;
  }

  #portfolio {
    display: grid;
    grid-gap: 40px;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-auto-rows: 285px;
    grid-auto-flow: dense;
    margin: auto;
    padding: 40px;
  }

  #portfolio > a {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2em;
    overflow: hidden;
  }

  #portfolio > a > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.5s ease-in-out
  }
  #portfolio > a > img:hover {
    transform: scale(1.1);
  }

  .horizontal {
    grid-column: span 2;
  }

  .vertical {
    grid-row: span 2;
  }

  .square {
    grid-column: span 2;
    grid-row: span 2;
  }

  @media (max-width: 640px) {
    #portfolio {
      grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
      grid-auto-rows: 115px;
      padding: 7px;
      grid-gap: 7px;
    }
    .main-container {
      width: 100%;
    }
  }
</style>
