<!-- src/pages/About.svelte -->
<script>
    export let mainHeaderText, mainText; // Receive the prop from App.svelte
    $: mainHeaderText = "About me";
    $: mainText = "Photographer and designer with a passion for UI/UX, programming, and travel. Constantly evolving and embracing creativity.";
  </script>
  

  
  <style>
   
  </style>

  <h2>page in works</h2>
  