<!-- src/App.svelte -->
<script>
  //Plugins
  import { Router, Route, Link } from "svelte-routing";
  import { fade, slide } from "svelte/transition";

  //Pages
  import Navbar from "./Navbar.svelte";
  import Home from "./pages/Home.svelte";
  import About from "./pages/About.svelte";
  import Portfolio from "./pages/Portfolio.svelte";
  import Contact from "./pages/Contact.svelte";
  import Gallery from "./pages/Gallery.svelte";

  //Exports
  let mainHeaderText = "Dominik";
  let mainText = "Always searching for new perspectives and stories in every frame.";
  $: combinedKey = `${mainHeaderText}-${mainText}`;
</script>

<Router>
  <main>
    <section>
      <header>
        <Navbar />
      </header>
      <section class="introText">
        {#key combinedKey}
          <h1 transition:slide>{mainHeaderText}</h1>
          <p
            out:slide={{ duration: 100 }}
            in:slide={{ delay: 300, duration: 400 }}
          >
            {mainText}
          </p>
        {/key}
      </section>
      <div class="socials">
        <a href="https://instagram.com/mrazdominik_">
          <i class="fab fa-instagram"></i>
        </a>
      </div>
    </section>
    <Route path="/" let:params>
      <Home bind:mainText bind:mainHeaderText />
    </Route>
    <Route path="/about" let:params>
      <About bind:mainText bind:mainHeaderText />
    </Route>
    <Route path="/portfolio" let:params>
      <Portfolio bind:mainText bind:mainHeaderText />
    </Route>
    <Route path="/contact" let:params>
      <Contact bind:mainText bind:mainHeaderText />
    </Route>
    <Route path="/gallery" let:params>
      <Gallery bind:mainText bind:mainHeaderText />
    </Route>
  </main>
</Router>

<style>
  main {
    height: 100%;
    width: 100%;
    display: flex;
    overflow: hidden;
  }
  header {
    align-self: normal;
  }
  h1 {
    margin: 0;
    color: var(--text1);
    font-size: 6vw;
    letter-spacing: 3px;
    margin-left: -9px;
    font-weight: 600;
    line-height: 7vw;
    position: relative;
    z-index: 2;
    text-shadow: #00000080 3px 3px 5px;
    transition: all 0.5s ease;
  }
  main > section:nth-child(1) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 35%;
    background: var(--bright2);
    transition: 1s all ease !important;
  }
  .introText {
    width: 82%;
  }
  .introText p {
    font-size: 1.2em;
    letter-spacing: 1.2px;
    margin: 0;
  }
  .socials {
    text-align: left;
    width: 82%;
    height: 70px;
    font-size: 1.4em;
  }
  .socials a {
    color: var(--text2);
    position: relative;
    transition: 0.3s ease;
    top: 0;
  }
  .socials a:hover {
    color: var(--text1);
    top: -2px;
  }

  @media (max-width: 640px) {
    main {
      max-width: none;
      overflow: auto;
      flex-direction: column;
    }

    main > section:nth-child(1) {
      width: 100%;
      height: 100%;
    }

    h1 {
      font-size: 4em;
      line-height: normal;
    }

    .introText p {
      font-size: 1em;
    }

    .socials {
      display: flex;
      align-items: center;
    }
  }
</style>
