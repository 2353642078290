<!-- src/pages/Galerry.svelte -->
<script>
  import { onMount } from "svelte";
  import PhotoSwipeLightbox from "photoswipe/lightbox";
  import PhotoSwipe from "photoswipe";
  const jq = window.$;

  export let mainHeaderText, mainText; // Receive the prop from App.svelte
  $: mainHeaderText = "Gallery";
  $: mainText = galleryID;

  function decodeBase64(base64Str) {
    return atob(base64Str);
  }

  // Extract galleryId from the URL
  const urlParams = new URLSearchParams(window.location.search);
  var galleryID = decodeBase64(urlParams.get("id") || "");

  let images = [];

  async function loadImages(galleryID) {
    try {
      const response = await fetch(`/img/main/gallery/${galleryID}/list.json`);
      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      images = await response.json();
    } catch (error) {
      console.error("Error loading JSON:", error);
    }
  }

  loadImages(galleryID);
  console.log(images);

  onMount(() => {
    const lightbox = new PhotoSwipeLightbox({
      gallery: "#portfolio",
      children: "a",
      pswpModule: () => Promise.resolve(PhotoSwipe), // Static import
    });

    lightbox.on("uiRegister", function () {
      lightbox.pswp.ui.registerElement({
        name: "download-button",
        order: 8,
        isButton: true,
        tagName: "a",
        html: {
          isCustomSVG: true,
          inner:
            '<path d="M20.5 14.3 17.1 18V10h-2.2v7.9l-3.4-3.6L10 16l6 6.1 6-6.1ZM23 23H9v2h14Z" id="pswp__icn-download"/>',
          outlineID: "pswp__icn-download",
        },
        onInit: (el, pswp) => {
          el.setAttribute("download", "");
          el.setAttribute("target", "_blank");
          el.setAttribute("rel", "noopener");

          pswp.on("change", () => {
            el.href = pswp.currSlide.data.src;
          });
        },
      });
    });

    lightbox.init();
  });

  function downloadGallery() {
    window.location.href = `/downloadgallery/${galleryID}`;
  }
</script>

<svelte:head>
  <meta property="og:title" content="Your Page Title" />
  <meta property="og:description" content="Your page description here." />
  <meta property="og:image" content="https://dominikmraz.net/favicon.png" />
  <meta property="og:url" content="https://dominikmraz.net/gallery" />
  <meta property="og:theme-color" content="#255356" />
  <meta data-n-head="true" name="theme-color" content="#255356" />
</svelte:head>

<div class="main-container">
  <section>
    <div class="downloadbutton">
      <button on:click={downloadGallery}>Download all</button>
    </div>
    <div class="image-container" id="portfolio">
      {#each images as { src, payload }}
        <a
          target="_blank"
          href="/{src}"
          data-pswp-width={payload.width}
          data-pswp-height={payload.height}
          class={payload.orientation}
          rel="noreferrer"
        >
          <img class="" alt="Loading.." src="/{payload.thumb}" />
        </a>
      {/each}
    </div>
  </section>
</div>

<style>
  section {
    width: 100%;
  }
  .main-container {
    display: flex;
    width: 65%;
    overflow-y: auto;
  }
  .downloadbutton {
    padding: 5px 5px 0 5px;
  }
  .downloadbutton button {
    width: 100%;
    padding: 5px;
    font-size: 3.5vh;
    background: none;
    border: 1px solid var(--bright2);
    color: var(--bright2);
  }
  #portfolio {
    display: grid;
    grid-gap: 40px;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-auto-rows: 285px;
    grid-auto-flow: dense;
    margin: auto;
    padding: 40px;
  }

  #portfolio > a {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2em;
    overflow: hidden;
  }

  #portfolio > a > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  #portfolio > a > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.5s ease-in-out
  }
  #portfolio > a > img:hover {
    transform: scale(1.1);
  }

  .horizontal {
    grid-column: span 2;
  }

  .vertical {
    grid-row: span 2;
  }

  .square {
    grid-column: span 2;
    grid-row: span 2;
  }

  @media (max-width: 640px) {
    #portfolio {
      grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
      grid-auto-rows: 115px;
      padding: 7px;
      grid-gap: 7px;
    }
    .main-container {
      width: 100%;
    }
  }
</style>
